import OpenAI from 'openai';
import toast from 'react-hot-toast';

export const openAI_commands = {
	GET_DETAILS: {
		key: 'chat.completions.create',
		model: 'gpt-3.5-turbo',
		max_tokens: 700,
		initialLine: 'Give me some details about : ',
	},
	GET_POINTS: {
		key: 'chat.completions.create',
		model: 'gpt-3.5-turbo',
		max_tokens: 700,
		initialLine:
			'write 5 lines formatted like a string and seperate each point with | about  ',
	},
	REWRITE_LINE: {
		key: 'chat.completions.create',
		model: 'gpt-3.5-turbo',
		max_tokens: 700,
		initialLine: 'Rewrite the text ',
	},
};

const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,

	dangerouslyAllowBrowser: true,
});

export const openAIService = async (command, topic) => {
	// const openai = getOpenai();

	// // console.log(command, topic);

	const response = await openai.chat.completions.create({
		model: command.model,
		// prompt: `${command.initialLine}"${topic}"`,
		messages: [{ role: 'user', content: `${command.initialLine}"${topic}"` }],
		max_tokens: command.max_tokens,
		temperature: 0.85,
	});

	// // console.log(response);
	// // console.log(`${command.initialLine}"${topic}"`);
	return response.choices[0]?.message?.content;
};

// const openai2 = new OpenAI({
// 	baseURL:
// 		'https://chatgpt.com/g/g-5OTwSc7qF-digital-player-development-coordinator',
// 	dangerouslyAllowBrowser: true,
// });

export const fetchSessions = async (inputText) => {
	try {
		// First API call: Create a thread/message
		const res = await fetch(
			'https://api.openai.com/v1/threads/thread_HK7fQUMb0L2CCHzlfmTCTazW/messages',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
					'OpenAI-Beta': 'assistants=v2',
				},
				body: JSON.stringify({
					role: 'user',
					content: inputText ?? '',
				}),
			}
		);

		if (!res.ok) {
			throw new Error(`HTTP error! status: ${res.status}`);
		}

		const data = await res.json();
		// console.log('First API Response:', data);

		const threadId = data.thread_id;

		// Second API call: Start the thread run using the thread_id
		const secondRes = await fetch(
			`https://api.openai.com/v1/threads/${threadId}/runs`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
					'OpenAI-Beta': 'assistants=v2',
				},
				body: JSON.stringify({
					assistant_id: 'asst_ZpBqis6ftRFDFbQKroN7Gzax',
				}),
			}
		);

		if (!secondRes.ok) {
			throw new Error(`HTTP error! status: ${secondRes.status}`);
		}

		const secondData = await secondRes.json();
		// console.log('Second API Response:', secondData);

		const runId = secondData.id;

		// Poll the third API until the status is 'completed'
		const pollRunStatus = async (
			threadId,
			runId,
			interval = 5000,
			maxAttempts = 12
		) => {
			let attempts = 0;

			while (attempts < maxAttempts) {
				try {
					const thirdRes = await fetch(
						`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
						{
							method: 'GET',
							headers: {
								Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
								'OpenAI-Beta': 'assistants=v2',
							},
						}
					);

					if (!thirdRes.ok) {
						throw new Error(`HTTP error! status: ${thirdRes.status}`);
					}

					const thirdData = await thirdRes.json();
					// console.log('Third API Response:', thirdData);

					// Check if the status is 'completed'
					if (thirdData.status === 'completed') {
						// console.log('Run is completed.');
						return thirdData;
					}

					// Wait for the interval before the next attempt
					await new Promise((resolve) => setTimeout(resolve, interval));
					attempts += 1;
				} catch (error) {
					console.error('Error while polling run status:', error);
					await new Promise((resolve) => setTimeout(resolve, interval));
					attempts += 1;
				}
			}

			throw new Error(
				'Polling timed out. The run did not complete in the expected time.'
			);
		};

		// Start polling the third API
		const runData = await pollRunStatus(threadId, runId);

		// Fourth API call: Fetch the message thread
		const fourthRes = await fetch(
			`https://api.openai.com/v1/threads/thread_HK7fQUMb0L2CCHzlfmTCTazW/messages`,
			{
				method: 'GET',
				headers: {
					Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
					'OpenAI-Beta': 'assistants=v2',
				},
			}
		);

		if (!fourthRes.ok) {
			throw new Error(`HTTP error! status: ${fourthRes.status}`);
		}

		const fourthData = await fourthRes.json();
		// console.log('Fourth API Response:', fourthData);

		// Extract the relevant message content associated with the runId
		const input_ = fourthData.data?.filter((item) => item.run_id === runId)[0]
			?.content[0]?.text.value;

		// console.log('fourth');

		if (!input_) {
			throw new Error('No input found in the fourth API response.');
		}

		const match = input_.match(/<LeftMid>(.*?)<LeftMid>/);

		if (match && match[1]) {
			return match[1].split(',');
		}

		// If the pattern doesn't match, return an empty array
		return [];
	} catch (error) {
		toast.error('Error getting drills from AI.');
		console.error('Error during API calls:', error);
		return [];
	}
};
