import React, { useEffect, useState } from 'react';

function PsychoRatingCounts({ results, ratingName }) {
	const [counts, setCounts] = useState();

	// console.log(results)
	useEffect(() => {
		// Group the ratings by name
		const groupedRatings = {};
		results.forEach((rating) => {
			const { name, rating: ratingName, count } = rating;
			if (!groupedRatings[name]) {
				groupedRatings[name] = [];
			}
			groupedRatings[name].push({ ratingName, count });
		});
		setCounts(groupedRatings);
	}, [results]);

	return (
		<div>
			{counts && (
				<div>
					{Object.entries(counts).map(([name, ratings]) => (
						<div key={name}>
							{/* <h3>{name}:</h3> */}
							{ratingName.toLocaleLowerCase() == name.toLocaleLowerCase() && (
								<>
									{ratings.map(({ ratingName, count }) => (
										<p key={ratingName} style={{ margin: 0 }}>
											{ratingName}({count})
										</p>
									))}
								</>
							)}
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default PsychoRatingCounts;
