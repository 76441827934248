import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon for the close button
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	IconButton,
	Modal,
	Stack,
	Typography,
} from '@mui/material';
import { fetchSessions } from 'config/openAI';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import {
	checkSessionsExitOrNot,
	getAllTrainingDrillsForClub,
	getTrainingDrills,
	uploadSession,
} from 'global/redux/player/request';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import UpdateDrill from './update_training_sessions';

const GeneratedTrainingSessions = ({ startDate, endDate, days, inputText }) => {
	const [open, setOpen] = useState(false);
	const [slots, setSlots] = useState([]); // Initialize with an empty array
	const [loading, setLoading] = useState(false); // Set initial loading state to false
	const currentTeam = useSelector(({ assessment }) => assessment.currentTeam);
	const [saving, setSaving] = useState(false);
	const [generatePressed, setGeneratePressed] = useState();
	const [allTrainingDrills, setAllTrainingDrills] = useState(null);

	const handleCheckboxChange = (slot) => {
		// setSelectedSlots((prevSelectedSlots) => {
		// 	if (prevSelectedSlots.some((s) => s.date === slot.date)) {
		// 		// Deselecting the slot
		// 		return prevSelectedSlots.filter((s) => s.date !== slot.date);
		// 	} else {
		// 		// Selecting the slot
		// 		return [...prevSelectedSlots, slot];
		// 	}
		// });
		setSlots((prevSlots) =>
			prevSlots.map((s) =>
				s.date === slot.date ? { ...s, checked: !s.checked } : s
			)
		);
	};

	const handleDrillCheckboxChange = (slotIndex, postIndex) => {
		setSlots((prevSlots) =>
			prevSlots.map((slot, sIndex) =>
				sIndex === slotIndex
					? {
							...slot,
							posts: slot.posts.map((post, pIndex) =>
								pIndex === postIndex
									? { ...post, checked: !post.checked }
									: post
							),
					  }
					: slot
			)
		);
	};

	// // console.log('selectedSlots', selectedSlots);
	// console.log('slots', slots);

	const isMatchingSlot = (arr1, arr2) => {
		for (const item of arr1) {
			const timeToPlayDate = new Date(item.timeToPlay.seconds * 1000);

			for (const dateString of arr2) {
				const date = new Date(dateString);
				if (timeToPlayDate.getTime() === date.getTime()) {
					// console.log(item);
					return date; // Return the matched timeToPlay date
				}
			}
		}
		return null; // Return null if no match is found
	};

	const handleOpenOrNot = async () => {
		setGeneratePressed(true);
		if (startDate != null && endDate != null && days != null) {
			if (slots?.length == 0) {
				toast.error(
					'No days in the selected range. select a different date range'
				);
				setGeneratePressed(false);

				return;
			}

			try {
				const hasSessions = await checkSessionsExitOrNot({
					teamId: currentTeam.club,
					startDateTime: startDate, // Ensure this is in a format that can be parsed
					slots: slots?.map((_slot) =>
						dayjs(_slot.date, 'DD/MM/YYYY h:mm A').toDate().toString()
					), // Ensure correct conversion
				});
				// console.log(
				//	'hasSessions',
				//	hasSessions.map((itemm) => {
				//	return itemm.createdAt != null && itemm;
				//		})
				//	);

				// if (hasSessions?.length > 0) {
				const result = isMatchingSlot(
					hasSessions,
					slots?.map((_slot) =>
						dayjs(_slot.date, 'DD/MM/YYYY h:mm A').toDate().toString()
					)
				);
				if (result != null) {
					toast.error(
						`You already have training session on " ${dayjs(result).format(
							'dddd MMM DD, YYYY hh:mm a'
						)}"`
					);
				} else {
					setOpen(true);
					fetchData();
				}
				// } else {
				// 	setOpen(true);
				// 	fetchData();
				// }
			} catch (error) {
				// console.log('hasSessions', error);
				toast.error('zerror checking session : hasSessions');
			}
		} else {
			toast.error('Select days, start date & end date');
		}
		setGeneratePressed(false);
	};

	const fetchData = async () => {
		// console.log('fetchData');
		if (!slots || slots.length === 0) return;
		try {
			setLoading(true);

			// for production
			// #############################
			const sessionIds = await fetchSessions(inputText);
			const drills_ = await getTrainingDrills([...sessionIds], {
				club_id: currentTeam.club,
			});

			// for test
			// #############################
			// const drills_ = await getTrainingDrills(['E2A0DF40', '26F056EA']);

			if (drills_?.length == 0) {
				toast.error('No drills found in the database');
			}
			// // console.log('drills_', drills_);

			// console.log('before slotsWithData', slots);
			const slotsWithData = slots.map((slot, index) => ({
				...slot,
				checked: true,
				stockTime: startDate,
				posts: drills_?.map((d) => ({ ...d, checked: true })),
			}));
			// console.log('slotsWithData', slotsWithData);

			setSlots(slotsWithData);
		} catch (error) {
			toast.error('Error generating sessions');

			console.error('Error fetching data:', error);
		}
		setLoading(false);
	};

	const sendSession = async () => {
		setSaving(true);
		if (slots?.posts?.length != 0) {
			try {
				const hasSessions = await checkSessionsExitOrNot({
					teamId: currentTeam.club,
					startDateTime: startDate,
					slots: slots
						?.filter((slot) => slot.checked == true)
						?.map((_slot) => dayjs(_slot.date, 'DD/MM/YYYY h:mm A').toDate()),
				});
				// console.log('hasSessions', hasSessions);

				const result = isMatchingSlot(
					hasSessions,
					slots
						?.filter((slot) => slot.checked == true)
						?.map((_slot) =>
							dayjs(_slot.date, 'DD/MM/YYYY h:mm A').toDate().toString()
						)
				);
				// console.log('isMatchingSlot', result);

				if (result != null) {
					toast.error(
						`You already have training sessions on " ${dayjs(result).format(
							'dddd MMM DD, YYYY hh:mm a'
						)}"`
					);
				} else {
					const arrOfSessions = slots
						?.filter((slot) => slot.checked == true)
						.map((item) =>
							uploadTrainingSession({
								sessions: item.posts.filter((post) => post.checked == true),
								timeToPlay: item.date,
								teamID: currentTeam.club,
							})
						);
					// console.log(arrOfSessions);

					await uploadSession(arrOfSessions)
						.then((res) => {
							toast.success('Sessions saved');
							// // console.log(res);
						})
						.catch((error) => {
							toast.error('Error saving sessions');

							// console.log(error);
						});

					// console.log(arrOfSessions);
				}
			} catch (error) {
				// console.log('hasSessions', error);
				toast.error('Error saving sessions: hasSessions');
			}
		}
		setSaving(false);
	};

	function getDatesInRange() {
		if (!startDate || !endDate || !days || days.length === 0) return []; // Check for required inputs

		const dayMapping = {
			Sunday: 0,
			Monday: 1,
			Tuesday: 2,
			Wednesday: 3,
			Thursday: 4,
			Friday: 5,
			Saturday: 6,
		};

		let result = [];
		let current = startDate.clone(); // Clone the startDate to avoid mutating the original object

		while (current.isBefore(endDate) || current.isSame(endDate, 'day')) {
			let currentDay = current.day();
			if (
				days.includes(
					Object.keys(dayMapping).find((key) => dayMapping[key] === currentDay)
				)
			) {
				result.push({
					date: current.format('DD/MM/YYYY hh:mm a'), // Format date as required
					day: Object.keys(dayMapping).find(
						(key) => dayMapping[key] === currentDay
					),
				});
			}
			current = current.add(1, 'day'); // Move to the next day
		}

		return result;
	}

	function generateRandomLightPastelColor() {
		const r = Math.floor((255 + Math.random() * 255) / 2);
		const g = Math.floor((255 + Math.random() * 255) / 2);
		const b = Math.floor((255 + Math.random() * 255) / 2);
		return `#${r.toString(16).padStart(2, '0')}${g
			.toString(16)
			.padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
	}

	const uploadTrainingSession = ({ sessions, teamID, timeToPlay }) => {
		const parsedDate = dayjs(timeToPlay, 'DD/MM/YYYY hh:mm a');
		const dateObject = parsedDate.toDate();
		return {
			teamIDs: `empty-${teamID}`,
			teamBCustom: null,
			[teamID]: null,
			teamID: null,
			noteVideo: [
				...sessions.map((item) => {
					return { url: item.youtube_link, title: item.name_of_session };
				}),
			],
			teams: [teamID, 'empty'],
			rating: null,
			status: 'waiting',
			stadium: null,
			timeToPlay: Timestamp.fromDate(dateObject),
			event: '4kviwcHueMOiDWK1sDDC',
			drillModels: [...sessions],
			notes: null,
			empty: null,
		};
	};

	// const handleUpdateSlots = (newDrillSlot) => {
	// 	// console.log('updatedSlots', newDrillSlot);
	// 	setSlots(newDrillSlot);
	// };
	// // console.log('updatedSlots slots', slots);

	useEffect(() => {
		if (startDate && endDate && days) {
			const __slots = getDatesInRange();
			setSlots(__slots);
		}
		if (!allTrainingDrills) {
			getAllTrainingDrillsForClub(currentTeam.club)
				.then((res) => setAllTrainingDrills(res))
				.catch((err) => console.log(err));
		}
	}, [startDate, endDate, days]);

	return (
		<>
			<LoadingButton
				fullWidth
				disableElevation
				loading={generatePressed}
				variant='contained'
				sx={{ color: 'white' }}
				onClick={() => {
					handleOpenOrNot();
				}}
			>
				Generate
			</LoadingButton>
			<Modal
				open={open}
				// onClose={() => {
				// 	setOpen(false);
				// }}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '90vw',
						height: '80vh',
						bgcolor: 'background.paper',
						borderRadius: 2,
						p: 3,
						overflowY: 'auto',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography variant='h4' id='modal-modal-title'>
							Training Sessions
						</Typography>
						<IconButton
							onClick={() => {
								setOpen(false);
							}}
							aria-label='close'
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box sx={{ flex: 1, overflowY: 'auto' }}>
						{loading ? (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: '100%',
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<Grid container spacing={4} wrap='wrap'>
								{slots?.map((slot, index) => (
									<Grid
										item
										xs={12} // 1 record per column (mobile / <1100px)
										// lg={6} // 2 records per column (>=1100px)
										xl={6} // 3 records per column (>=2000px)
										key={index}
									>
										<Box
											sx={{
												border: '1px solid #ccc',
												borderRadius: '8px',
												p: 2,
												width: '100%',
												// backgroundColor: () => generateRandomLightPastelColor(),
												backgroundColor: pastelColors[index],
											}}
										>
											{/* Checkbox with Date Heading */}
											<FormControlLabel
												control={
													<Checkbox
														checked={slot.checked}
														onChange={() => handleCheckboxChange(slot)}
													/>
												}
												label={
													<Typography variant='h5' fontWeight='bold'>
														{`${slot.date} - ${slot.day}`}
													</Typography>
												}
											/>

											{/* Posts Mapped for the Slot */}
											<Stack spacing={2}>
												{slot?.posts?.map((item, itemIndex) => (
													<Box
														key={itemIndex}
														sx={{
															display: 'flex', // Horizontal layout
															alignItems: 'start',
															gap: 2,
															border: '1px solid #ccc',
															borderRadius: '8px',
															p: 2,
														}}
													>
														{/* Video Thumbnail on the Left */}
														<Box
															sx={{
																// width: '150px',
																height: 'auto',
																flexShrink: 0,
															}}
														>
															<ReactPlayer
																width='100%'
																height='100%'
																controls
																url={item.youtube_link}
															/>
														</Box>

														{/* Text Content on the Right */}
														<Stack spacing={1} sx={{ flex: 1 }}>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'space-between',
																}}
															>
																<div
																	style={{
																		backgroundColor: 'white',
																		width: '50px',
																		height: '25px',
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		border: '1px solid lightgrey',
																		borderRadius: '5px',
																		opacity: '0.7',
																	}}
																>
																	{itemIndex + 1}
																</div>
																<div>
																	<UpdateDrill
																		slots={slots}
																		selectedSlotIndex={index}
																		selectedTrainingDrill={item}
																		trainingDrillIndex={itemIndex}
																		allTrainingDrills={allTrainingDrills}
																		updateDrill={(_slots) => setSlots(_slots)}
																	/>
																	{/* Checkbox with Date Heading */}
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={item.checked}
																				onChange={() =>
																					handleDrillCheckboxChange(
																						index,
																						itemIndex
																					)
																				}
																			/>
																		}
																	/>
																</div>
															</div>
															<Typography variant='h5'>
																{item?.name_of_session}
															</Typography>
															<Typography variant='body1'>
																{item?.duration_of_training_drill}
															</Typography>
															<Typography variant='body2'>
																{item?.ability_suitable_for_and_comments}
															</Typography>
														</Stack>
													</Box>
												))}
											</Stack>
										</Box>
									</Grid>
								))}
							</Grid>
						)}
					</Box>

					<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
						<LoadingButton
							loading={saving}
							disabled={loading || slots[0]?.posts?.length == 0}
							variant='contained'
							onClick={() => {
								// Handle submit action here
								sendSession();
							}}
						>
							Submit
						</LoadingButton>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default GeneratedTrainingSessions;

const pastelColors = [
	'#fcfaf2', // Light Cream
	'#d4f7d2', // Soft Green
	'#f7d3e8', // Pinkish Mauve
	'#e0d1f4', // Lavender
	'#f6e9d8', // Beige
	'#d9e9f4', // Soft Blue
	'#f5f2db', // Pale Yellow
	'#e9ffe4', // Light Mint Green
	'#fbf3d2', // Light Mustard
	'#e0fcd7', // Pastel Green
	'#f5d2f9', // Light Purple
	'#fdd8d4', // Light Coral
	'#d7fbef', // Mint Green
	'#f7f9e5', // Soft Lemon
	'#f4edf4', // Light Lilac
	'#d9f5d9', // Light Green
	'#f8f7f2', // Soft Grey
	'#e9eee9', // Muted Green-Grey
	'#daeee6', // Mint Aqua
	'#dce6f0', // Pale Blue
	'#f3e7dc', // Light Sand
	'#f0f4e6', // Pale Mint
	'#f8e1ea', // Soft Blush Pink
	'#e6dbec', // Lavender Grey
	'#e2ebe3', // Mist Green
	'#d6e4ef', // Light Sky Blue
	'#ede5f9', // Faint Lavender
	'#e5e9e0', // Off-White Green
	'#f5f7e1', // Light Lemon
	'#f1fce0', // Light Chartreuse
	'#f4d4fb', // Pale Purple
	'#f7e3e4', // Soft Rose
	'#e2fbf7', // Icy Aqua
	'#eceae7', // Warm Grey
	'#f6f1f6', // Light Mauve
	'#f2dedd', // Soft Peach
	'#e4f4e8', // Light Teal
	'#f3fbe4', // Pale Lime
	'#fef5f2', // Pale Peach
	'#e4e7f9', // Soft Indigo
	'#d9e4eb', // Light Blue-Grey
	'#efd5e2', // Faint Pink
	'#e5f0fa', // Ice Blue
	'#ecd8e4', // Faded Plum
	'#e5d5f7', // Light Violet
	'#fcf4eb', // Pale Sand
	'#dbeaf7', // Sky Blue
	'#f7f5d9', // Light Wheat
	'#fae5e4', // Blush Pink
	'#fdeedf', // Peach Beige
	'#e8dbd8', // Faded Peach
	'#dce9fc', // Soft Cyan
	'#e8dcd9', // Faded Salmon
	'#f8e8e8', // Light Coral Pink
	'#f2eff2', // Soft Greyish Lilac
	'#f6ece7', // Warm Beige
	'#f0e4e8', // Light Rose Grey
	'#e4fbe7', // Pale Mint
	'#ecdbe4', // Soft Mauve
	'#f3f4f3', // Neutral Grey
	'#dcfede', // Mint Green
	'#fde8f4', // Pale Rose
	'#e5f3e0', // Fresh Green
	'#dbe7ff', // Light Periwinkle
	'#e8fbf2', // Soft Aqua
	'#f6e9fa', // Light Lilac Pink
	'#dcfffe', // Light Cyan
	'#e1f2ed', // Soft Teal
	'#f0e6f9', // Faint Lavender
	'#dee8ec', // Light Greyish Blue
	'#e8d9da', // Faded Blush
	'#d6effc', // Light Ice Blue
	'#f1e6f2', // Light Plum
	'#fdece7', // Warm Pinkish Beige
	'#ece6ec', // Pale Mauve
	'#efdde4', // Faded Pink Beige
	'#ece7dc', // Cream Beige
	'#fcfbfc', // Pale Greyish White
	'#eaeff9', // Soft Blue-White
	'#dffcf6', // Soft Mint Aqua
	'#dae6de', // Light Grey-Green
	'#d9def8', // Faded Periwinkle
	'#d8eef0', // Soft Aqua Grey
];
