import { LoadingButton } from '@mui/lab';

import {
	Box,
	Button,
	CircularProgress,
	Modal,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { CopyAllOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import MultipleSelectChip from 'components/multiselect/multiselect.widget';
import dayjs from 'dayjs';
import { toggleAddEvent } from 'global/redux/assessment/slice';
import { getTeamEventLists } from 'global/redux/assessment/thunk';
import { getPlayerAssessments } from 'global/redux/player/request';
import GeneratedTrainingSessions from './generated_training_sessions';

const TrainingSessions = ({ teamId }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [content, setContent] = useState([]);
	const userInfo = useSelector(({ auth }) => auth.userInfo);
	const params = useParams();
	const { eventId, assessmentId } = params;

	const { eventList, fetchedEvent, addingEvent, assessmentInfo } = useSelector(
		({ assessment }) => ({
			eventList: assessment.eventList,
			fetchedEvent: assessment.fetchedEvent,
			addingEvent: assessment.addingEvent,
			assessmentInfo: assessment.assessmentInfo,
		})
	);
	const { playerInAssessment } = useSelector(({ assessment }) => assessment);
	const options = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	];
	const [selectedOptions, setSelectedOptions] = React.useState([]);
	const handleChangeMultiSelect = (event) => {
		const {
			target: { value },
		} = event;
		setSelectedOptions(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};

	const handleOpen = useCallback(() => {
		setReflections(null);
		getPlayerAssessments(assessmentId).then((res) => setReflections(res));
		if (!addingEvent) {
			dispatch(getTeamEventLists(teamId));
			dispatch(toggleAddEvent());
		}
		setOpen(true);
	}, [dispatch, teamId, addingEvent]);

	const handleChange = (event, value) => {
		setContent(event.target.value);
	};
	// // console.log(teamId);
	const handleAddOption = useCallback(
		(e) => {
			// // console.log(e);
			setContent((prev) => prev + ' ' + e + ' ');
		},
		[setContent]
	);

	const [reflections, setReflections] = useState();
	useEffect(() => {
		if (assessmentId != null) {
			getPlayerAssessments(assessmentId).then((res) => setReflections(res));
		}
	}, [assessmentId]);

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();

	// useEffect(() => {
	// 	fetchModelResponse('give me some session ids for first touch')
	// 		.then((res) => // console.log(res))
	// 		.catch((e) => // console.log(e));
	// }, []);

	return (
		<>
			<Button
				disableElevation
				disabled={assessmentInfo.coachID !== userInfo.id}
				variant='contained'
				onClick={handleOpen}
				sx={{
					color: 'white',
				}}
			>
				Traning Sessions
			</Button>
			<Modal
				open={open}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '90vw',
						height: '80vh',

						bgcolor: 'background.paper',
						borderRadius: 2,
						p: 3,
					}}
				>
					{' '}
					{reflections != null ? (
						<Box>
							<Stack direction='row' spacing='10px'>
								<Box>
									<Typography variant='h3'>Coach Reflections</Typography>
									<Box width='300px' height='70vh' overflow='scroll'>
										{reflections?.map((item, index) => (
											<div>
												{item?.reflection != null && (
													<ReflectionCopyWidget
														key={index}
														reflection={reflections}
														index={index}
														item={item}
														handleAddOption={handleAddOption}
													/>
												)}
											</div>
										))}
									</Box>
								</Box>
								<Box width='100%' height='70vh'>
									<Typography variant='h3'>Edit</Typography>
									<Box>
										<TextField
											id='outlined-multiline-static'
											label=''
											multiline
											value={content}
											onChange={handleChange}
											placeholder='Enter your feedbacks ...'
											rows={23}
											sx={{
												mt: 2,
												mb: 2,
												width: '100%',
												'& label.Mui-focused': {
													color: 'black',
												},
												'& .MuiInput-underline:after': {
													borderBottomColor: 'black',
												},
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'black',
														borderRadius: '5px',
													},
													'&:hover fieldset': {
														borderColor: 'black',
													},
													'&.Mui-focused fieldset': {
														borderColor: 'black',
													},
												},
											}}
										/>
									</Box>
									<div
										style={{
											display: 'flex',
											justifyContent: 'end',
											gap: '10px',
										}}
									></div>
								</Box>

								<Box>
									<Typography variant='h3'>Training Sessions</Typography>

									<Box
										width='310px'
										height='70vh'
										paddingTop='10px'
										display='flex'
										flexDirection='column'
										justifyContent='space-between'
									>
										<div>
											<MultipleSelectChip
												options={options}
												selectedOptions={selectedOptions}
												handleChange={handleChangeMultiSelect}
												label='Training Days'
												id='demo-multiple-chip'
											/>
											{/* <Button onClick={() => getGames()}>
												LyNwtnJNr0mApOCJEWHl
											</Button> */}

											<DateTimePicker
												sx={{ width: '300px', margin: '10px' }}
												label='Start Date'
												InputLabelProps={{
													shrink: true,
												}}
												slotProps={{
													textField: {
														readOnly: true,
													},
												}}
												onChange={(e) => {
													setStartDate(e);
												}}
												minDate={dayjs()}
												maxDate={endDate && dayjs(endDate)}
											/>

											<DateTimePicker
												sx={{ width: '300px', margin: '10px' }}
												label='End Date'
												InputLabelProps={{
													shrink: true,
												}}
												slotProps={{
													textField: {
														readOnly: true,
													},
												}}
												onChange={(e) => setEndDate(e)}
												minDate={dayjs(startDate).add(1, 'days')}
												maxDate={
													startDate ? dayjs(startDate).add(42, 'days') : dayjs()
												}
											/>
										</div>

										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '10px',
												margin: '10px',
											}}
										>
											<GeneratedTrainingSessions
												startDate={startDate}
												endDate={endDate}
												days={selectedOptions}
												inputText={content}
											/>
											<LoadingButton
												fullWidth
												variant='outlined'
												onClick={() => setOpen(false)}
											>
												Cancel
											</LoadingButton>
										</div>
									</Box>
								</Box>
							</Stack>
						</Box>
					) : (
						<CircularProgress />
					)}
				</Box>
			</Modal>
		</>
	);
};

export default TrainingSessions;

const ReflectionCopyWidget = ({ reflection, index, item, handleAddOption }) => {
	const content = item?.reflection?.content;
	const truncatedContent =
		content.length > 100 ? content.substring(0, 100) + '...' : content; // Adjust the length as needed

	// // console.log({ reflection, index, item, handleAddOption });
	return (
		<div
			style={{
				border: '1px solid #d1d1d1',
				margin: '10px 10px 10px 0px',
				padding: '10px',
				borderRadius: '10px',
			}}
		>
			<Typography variant='h6'>
				{reflection[index]?.userInfo?.firstName}{' '}
				{reflection[index]?.userInfo?.lastName}
			</Typography>

			<Tooltip
				placement='top'
				title={
					<React.Fragment>
						<Typography variant='h6' component='div' color='white'>
							{reflection[index]?.userInfo?.firstName}{' '}
							{reflection[index]?.userInfo?.lastName}
						</Typography>
						<p style={{ fontSize: '12px', fontWeight: '400' }}>{content}</p>
					</React.Fragment>
				}
			>
				<p
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: 5,
						WebkitBoxOrient: 'vertical',
					}}
				>
					{truncatedContent}
					<CopyAllOutlined
						style={{ cursor: 'pointer', marginLeft: '8px' }}
						onClick={() => handleAddOption(content)}
					/>
				</p>
			</Tooltip>
		</div>
	);
};
