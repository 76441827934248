import { firebaseDb } from 'services';
import {
	doc,
	getDoc,
	updateDoc,
	getDocs,
	collection,
	orderBy,
	query,
	deleteField,
	serverTimestamp,
	where,
	collectionGroup,
	limit,
	addDoc,
	Timestamp,
	setDoc,
} from 'firebase/firestore';
import { getDataByBatch } from '../auth/request';
import toast from 'react-hot-toast';

const getSelfImprove = async (playerAssessmentId) => {
	// // console.log('playerAssessmentId', playerAssessmentId);
	const selfImprove = [];
	const ref = collection(firebaseDb, 'player_reflection');
	const q = query(ref, where('assessmentID', '==', playerAssessmentId));
	const res = await getDocs(q);
	res.forEach((item) => {
		selfImprove.push(item.data());
	});
	return selfImprove[0];
};

const getAttendence = async (playerId, events) => {
	function chunkArray(array, chunkSize) {
		const chunks = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunks.push(array.slice(i, i + chunkSize));
		}
		return chunks;
	}

	const chunkedEvents = chunkArray(events, 10);
	const result = [];

	const ref = collectionGroup(firebaseDb, 'assesment_meta');

	const queries = chunkedEvents.map((chunk) => {
		const q = query(
			ref,
			where('attended', '==', true),
			where('gameID', 'in', chunk),
			where('userID', '==', playerId)
		);
		return getDocs(q);
	});

	const results = await Promise.all(queries);

	results.forEach((snapshot) => {
		snapshot.forEach((item) => {
			result.push({ ...item.data(), id: item?.id });
		});
	});

	return result;
};

const getCoachFeedback = async (playerAssessmentId) => {
	const feedbacks = [];
	const ref = collection(firebaseDb, 'coach_feedback');
	const q = query(ref, where('assessmentID', '==', playerAssessmentId));
	const res = await getDocs(q);
	res.forEach((item) => {
		feedbacks.push(item.data());
	});
	return feedbacks;
};

const getCoachInEvent = async (eventId) => {
	const coachList = [];
	const ref = collection(firebaseDb, `games/${eventId}/coach_id`);
	const res = await getDocs(ref);
	res.forEach((item) => {
		coachList.push(item.id);
	});
	return coachList;
};

// No use currently
const getNote = async (eventId, coachId, playerId) => {
	const ref = doc(
		firebaseDb,
		`games/${eventId}/coach_id/${coachId}/training_note/${playerId}`
	);
	const res = await getDoc(ref);
	return res.data();
};

const getNotev2 = async (playerId, eventId) => {
	const training_note = [];
	const ref = collectionGroup(firebaseDb, 'training_note');
	const q = query(
		ref,
		where('id', '==', playerId),
		where('eventID', '==', eventId)
	);
	const res = await getDocs(q);
	res.forEach((item) => {
		training_note.push(item.data());
	});

	return training_note;
};

const getNotev3 = async (playerId, eventId, clubId) => {
	// console.log({ playerId: playerId, eventId: eventId, clubId: clubId });
	const training_note = [];

	const gamesRef = collection(firebaseDb, 'games');
	const gameDocRef = doc(gamesRef, eventId);
	const assessmentMetaRef = collection(gameDocRef, 'assesment_meta');

	const q = query(
		assessmentMetaRef,
		where('userID', '==', playerId),
		where('gameID', '==', eventId),
		where('clubID', '==', clubId)
	);
	const querySnapshot = await getDocs(q);
	querySnapshot.forEach((item) => {
		training_note.push(item.data());
	});

	// // console.log({
	// 	playerId, eventId, clubId
	// })
	// // console.log("training_note - request.js", training_note)

	return training_note;
};

const getPlayerAssessmentInfo = async (assessmentId, playerAssessmentId) => {
	const ref = doc(
		firebaseDb,
		`assessment/${assessmentId}/player_assessment/${playerAssessmentId}`
	);
	console.log(
		`assessment/${assessmentId}/player_assessment/${playerAssessmentId}`
	);
	const data = await getDoc(ref);
	// console.log('Get player reflection', data.data());
	return data.data();
};

const getPlayerReflectionsRequest = async (playerId) => {
	const ref = collectionGroup(firebaseDb, 'player_assessment');
	const q = query(
		ref,
		where('userID', '==', playerId),
		orderBy('createdAt', 'desc'),
		limit(1)
	);

	const querySnapshot = await getDocs(q);
	const reflections = querySnapshot.docs.map((doc) => doc.data());

	// // console.log('Get player reflections', reflections);
	return reflections[0];
};

const updatePsychology = async (
	assessmentId,
	playerAssessmentId,
	label,
	value
) => {
	const updateData = {
		[label]: value,
	};
	const userRef = doc(
		firebaseDb,
		`assessment/${assessmentId}/player_assessment/${playerAssessmentId}`
	);
	await updateDoc(userRef, updateData);
};

const addReflection = async (
	assessmentId,
	playerAssessmentId,
	content,
	coachId,
	coachName
) => {
	const userRef = doc(
		firebaseDb,
		`assessment/${assessmentId}/player_assessment/${playerAssessmentId}`
	);
	await updateDoc(
		userRef,
		{
			reflection: {
				coachId: coachId,
				coachName: coachName,
				content: content,
				createdAt: serverTimestamp(),
			},
		},
		{ merge: true }
	);
	return assessmentId;
};

const editReflection = async (
	assessmentId,
	playerAssessmentId,
	content,
	coachId,
	coachName
) => {
	const userRef = doc(
		firebaseDb,
		`assessment/${assessmentId}/player_assessment/${playerAssessmentId}`
	);
	await updateDoc(
		userRef,
		{
			reflection: {
				coachId: coachId,
				coachName: coachName,
				content: content,
				createdAt: serverTimestamp(),
			},
		},
		{ merge: true }
	);
};

const deleteReflection = async (assessmentId, playerAssessmentId) => {
	const userRef = doc(
		firebaseDb,
		`assessment/${assessmentId}/player_assessment/${playerAssessmentId}`
	);
	await updateDoc(userRef, {
		reflection: deleteField(),
	});
};

const getPlayerSkill = async (teamId, playerId) => {
	const data = [];
	const userRef = collection(firebaseDb, `players/${playerId}/player_stats`);
	const q = query(
		userRef,
		where('organizeId', '==', teamId),
		orderBy('createAt', 'desc')
	);
	const res = await getDocs(q);
	res.forEach((item) => {
		data.push(item.data());
	});
	return data[0];
};

const updatePlayerSkillsRating = async (
	updatedRating,
	assessmentId,
	playerAssessmentId
) => {
	const userRef = doc(
		firebaseDb,
		`assessment/${assessmentId}/player_assessment/${playerAssessmentId}`
	);
	await updateDoc(
		userRef,
		{
			rating: updatedRating,
		},
		{ merge: true }
	);
};

const getPsychology = async (eventIds, userId) => {
	const batches = [];
	var result = [];

	// eventIds = ['0bdc67cXXfH0eqPepnQI'];

	const ref = collectionGroup(firebaseDb, 'assesment_meta');

	while (eventIds.length) {
		// firestore limits batches to 10
		const batch = eventIds.splice(0, 10);

		// add the batch request to to a queue
		batches.push(
			getDocs(
				query(ref, where('gameID', 'in', batch), where('userID', '==', userId))
			)
		);
	}
	// after all of the data is fetched, return it
	await Promise.all(batches).then((array) =>
		array.forEach((a) => {
			a.forEach((doc) => {
				result.push({ id: doc.id, ...doc.data() });
			});
		})
	);

	return result;
};

const getSkillRatings = async (playerId, range) => {
	try {
		const ref = collectionGroup(firebaseDb, 'assesment_meta');
		const assessment_metas = [];
		const q = query(
			ref,
			where('userID', '==', playerId),
			where('sessionRating', '!=', null)
		);
		const snapshot = await getDocs(q);
		snapshot.forEach((item) => {
			assessment_metas.push({ ...item.data(), id: item?.id });
		});

		try {
			const ref = collectionGroup(firebaseDb, 'games');
			const games = [];
			const q = query(
				ref,
				where('timeToPlay', '>=', range?.start),
				where('timeToPlay', '<=', range?.end)
			);
			const snapshot = await getDocs(q);
			snapshot.forEach((item) => {
				games.push({ ...item.data(), id: item?.id });
			});

			return assessment_metas?.filter((item2) =>
				games.some((item1) => item1.id === item2.gameID)
			);
		} catch (error) {
			// console.log('Error fetching games:', error);
		}
	} catch (error) {
		console.error('Error fetching skill ratings:', error);
		throw error;
	}
};

const getSkillRatingTable = async (teamId) => {
	const clubRef = doc(firebaseDb, `clubs/${teamId}`);
	const club = (await getDoc(clubRef)).data();

	if (club?.organizeID) {
		const organizeRef = doc(firebaseDb, `organizes/${club?.organizeID}`);
		const organize = (await getDoc(organizeRef)).data();
		return organize;
	} else {
		return new Error('Error fetching skill rating table.');
	}
};

const updatePlayerSkillsRatingDynamic = async (
	updatedRatingg,
	assessmentId,
	playerAssessmentId,
	age_group
) => {
	// console.log(updatedRatingg, assessmentId, playerAssessmentId, age_group);
	const userRef = doc(
		firebaseDb,
		`assessment/${assessmentId}/player_assessment/${playerAssessmentId}`
	);
	await updateDoc(
		userRef,
		{
			rating: updatedRatingg,
			age_group: age_group,
		},
		{ merge: true }
	);
};

// const gamesRef = collection(firebaseDb, 'games');
// const gameDocRef = doc(gamesRef, eventId);

// const getPlayerAssessmentInfo = async (assessmentId, playerAssessmentId) => {
// 	const ref = doc(
// 		firebaseDb,
// 		`assessment/${assessmentId}/player_assessment/${playerAssessmentId}`
// 	);
// 	const data = await getDoc(ref);
// 	// console.log('Get player reflection', data.data());
// 	return data.data();
// };

const fetchCoachReflectionBulk = async ({
	assessmentId,
	userIds,
	playerAssessmentId,
}) => {
	try {
		// Reference to the collection
		const ref = collection(
			firebaseDb,
			`assessment/${assessmentId}/player_assessment`
		);

		// Initialize a Firestore query
		const q = query(ref, where('id', 'in', userIds));

		// Execute the query
		const querySnapshot = await getDocs(q);

		// Extract and return the documents
		const documents = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
		return documents;
	} catch (error) {
		console.error('Error fetching documents: ', error);
		return [];
	}
};

const getPlayerAssessments = async (assessmentId) => {
	const ref = collection(
		firebaseDb,
		`assessment/${assessmentId}/player_assessment`
	);

	try {
		const snapshot = await getDocs(ref);
		const assessments = snapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
		const playerId = assessments.map((item) => item.userID);
		const playerList = await getDataByBatch(playerId, 'users');

		return assessments.map((item) => {
			return {
				...item,
				userInfo: playerList.filter((info) => info.id === item.userID)[0],
			};
		});
	} catch (error) {
		console.error('Error fetching player assessments:', error);
		throw error; // Rethrow the error so the caller can handle it
	}
};

const checkSessionsExitOrNot = async ({ teamId, startDateTime, slots }) => {
	// // console.log({ teamId, startDateTime, slots });
	const collectionRef = collection(firebaseDb, `games/`);

	const ref = query(
		collectionRef,
		where('teams', 'array-contains', teamId)
		// where('timeToPlay', 'in', slots)
	);

	try {
		const snapshot = await getDocs(ref);
		const sessions = snapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));

		return sessions;
	} catch (error) {
		console.error('Error fetching sessions:', error);
		throw error; // Rethrow the error so the caller can handle it
	}
};

const uploadDrills = async (drills) => {
	try {
		for (let drill of drills) {
			const docRef = doc(firebaseDb, 'session_data', drill.unique_identifier);
			await setDoc(docRef, {
				...drill,
				id: drill.unique_identifier,
				unique_identifier: drill.unique_identifier,
			});
			// console.log(`Document ${drill.unique_identifier} successfully uploaded!`);
		}
	} catch (error) {
		console.error('Error uploading document: ', error);
	}
};

const getTrainingDrills = async (sessionIDs, { club_id }) => {
	// console.log('sessionIDs', sessionIDs);
	const collectionRef = collection(firebaseDb, `session_data`);

	const ref = query(
		collectionRef,
		where('unique_identifier', 'in', sessionIDs)
		// where('club_id', '==', club_id)
	);

	try {
		const snapshot = await getDocs(ref);
		const _drills = snapshot.docs.map((doc) => ({
			...doc.data(),
		}));

		return _drills;
	} catch (error) {
		console.error('Error fetching player assessments:', error);
		throw error; // Rethrow the error so the caller can handle it
	}
};

const uploadSession = async (sessionArray) => {
	// Reference to the 'games' collection
	const collectionRef = collection(firebaseDb, 'games');

	try {
		// Iterate over the array and upload each document
		const promises = sessionArray.map(async (game) => {
			await addDoc(collectionRef, { ...game, createdAt: Timestamp.now() });
		});

		// Wait for all documents to be uploaded
		return await Promise.all(promises);
	} catch (error) {
		toast.error('Error saving sessions');
		console.error('Error uploading documents: ', error);
		return error;
	}
};

const getAllTrainingDrillsForClub = async (clubID) => {
	// console.log('clubID', clubID);
	const collectionRef = collection(firebaseDb, `session_data`);

	const ref = query(
		collectionRef
		// where('club_id', '==', clubID)
	);
	// // console.log('club_id', '==', clubID);

	try {
		const snapshot = await getDocs(ref);
		const _drills = snapshot.docs.map((doc) => ({
			...doc.data(),
		}));

		return _drills;
	} catch (error) {
		console.error('Error fetching player assessments:', error);
		throw error; // Rethrow the error so the caller can handle it
	}
};

export {
	addReflection,
	deleteReflection,
	editReflection,
	getCoachFeedback,
	getCoachInEvent,
	getNote,
	getNotev2,
	getNotev3,
	getPlayerAssessmentInfo,
	getPlayerSkill,
	getSelfImprove,
	updatePlayerSkillsRating,
	updatePsychology,
	getPsychology,
	getAttendence,
	getSkillRatings,
	getSkillRatingTable,
	updatePlayerSkillsRatingDynamic,
	getPlayerReflectionsRequest,
	fetchCoachReflectionBulk,
	getPlayerAssessments,
	getTrainingDrills,
	checkSessionsExitOrNot,
	uploadSession,
	uploadDrills,
	getAllTrainingDrillsForClub,
};
