import {
	Box,
	CircularProgress,
	Collapse,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { LoadingButton } from '@mui/lab';
import SearchBar from 'components/SearchBar';
import { openAIService, openAI_commands } from 'config/openAI';
import {
	addUserQuickSentence,
	deleteUserQuickSentence,
} from 'global/redux/auth/thunk';
import { SAMPLE_INPUT } from 'utils/constant';
import { style } from 'utils/constant/style';

const SampleInput = ({ onToggle, toggle, setContent, content, toggleNote }) => {
	const dispatch = useDispatch();
	const draft = useSelector(({ player }) => player.draft);
	const [lastReflection, setLastReflection] = useState(false);
	const [openSuggested, setOpenSuggested] = useState(false);
	const [userSentence, setUserSentence] = useState(false);
	const [toggleAddSentence, setToggleAddSentence] = useState(false);
	const [userInput, setUserInput] = useState('');
	const { quickSentence, userInfo } = useSelector(({ auth }) => ({
		quickSentence: auth.quickSentence,
		userInfo: auth.userInfo,
	}));
	const [sentences, setSentences] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleTogglePastInput = useCallback(() => {
		setLastReflection(!lastReflection);
	}, [lastReflection]);

	const handleToggleSuggestedInput = useCallback(() => {
		setOpenSuggested(!openSuggested);
	}, [openSuggested]);

	const handleToggleUserSentence = useCallback(() => {
		setUserSentence(!userSentence);
	}, [userSentence]);

	const handleAddOption = useCallback(
		(e) => {
			setContent((prev) => prev + ' ' + e);
		},
		[setContent]
	);

	const formateAndSetData = (arr) => {
		const formatedArr = [];
		for (let i = 0; i < arr.length; i++) {
			// const line = arr[i].split('.')[1];
			const line = arr[i];
			// // console.log(arr);
			if (line) {
				formatedArr.push(`${line.trim()}`);
			}
		}
		// // console.log(formatedArr);
		setSentences(formatedArr);
	};

	const getQuickSentences = useMemo(() => {
		return async () => {
			// console.log('getQuickSentences');
			setLoading(true);
			setSentences(null);
			if (content.length > 20) {
				// console.log('content.length > 20');
				await openAIService(openAI_commands.GET_DETAILS, content)
					.then((res) => {
						// // console.log('content.length > 20', res);
						setSentences(res);
					})
					.catch((err) => {
						// // console.log(err);
						setSentences('.');
					});
			} else if (content.length < 20 && content.length > 0) {
				// console.log('content.length < 20 && content.length > 0');
				await openAIService(openAI_commands.GET_DETAILS, content)
					.then((res) => {
						// // console.log('content.length < 20 && content.length > 0', res);
						setSentences(res);
					})
					.catch((err) => {
						// // console.log(err);
						setSentences('.');
					});
			} else if (content.length === 0) {
				setSentences('.');

				// console.log('content.length === 0');
			}
			setLoading(false);
		};
	}, []);

	// // console.log(sentences);
	// // console.log(loading);

	const handleDelete = useCallback(
		(content) => {
			dispatch(
				deleteUserQuickSentence({
					userId: userInfo.id,
					content: content,
				})
			);
		},
		[dispatch, userInfo.id]
	);

	const handleAddUserSentence = useCallback(() => {
		setToggleAddSentence(!toggleAddSentence);
	}, [toggleAddSentence]);

	const handleEnter = useCallback(
		(e) => {
			if (e.key === 'Enter' && !e.shiftKey && userInput) {
				dispatch(
					addUserQuickSentence({
						userId: userInfo.id,
						content: userInput,
					})
				);
				setUserInput('');
				setToggleAddSentence(false);
			}
		},
		[dispatch, userInfo.id, userInput]
	);

	const handleCopyNote = useCallback(
		(note) => {
			setContent((prev) => prev + ' ' + note);
			// // console.log(note);
		},
		[setContent]
	);

	// // console.log(content);

	// // console.log('toggle', toggle);
	// // console.log('toggleNote', toggleNote);

	useEffect(() => {
		if (content && content.length > 2) {
			if (toggle | (toggleNote == false)) {
				if (content.length > 5) {
					getQuickSentences();
				}
			}
		} else {
			setSentences('.');
		}
	}, [toggle, toggleNote]);

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box
			sx={{
				...style,
				boxShadow: 0,
				height: '95vh',
				width: '30vw',
				transform: toggleNote
					? 'translate(75%, -50%)'
					: 'translate(100%, -50%)',
				overflow: 'auto',
			}}
		>
			{value == 0 ? (
				<Typography variant='h2'>Quick Sentences</Typography>
			) : (
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<Typography variant='h2'>Generate Sentences</Typography>
					<Stack
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<LoadingButton
							onClick={() => getQuickSentences()}
							loading={loading}
						>
							Generate
						</LoadingButton>
					</Stack>
				</Stack>
			)}

			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Tabs
					sx={{ display: 'flex', justifyContent: 'center' }}
					value={value}
					onChange={handleChange}
					aria-label='basic tabs example'
				>
					<Tab label='Quick Sentences' {...a11yProps(0)} />
					<Tab label='Generate Sentences' {...a11yProps(1)} />
				</Tabs>
			</Box>

			<TabPanel value={value} index={0}>
				<>
					<List
						sx={{
							width: '100%',
							height: '80%',
							overflow: 'auto',
							bgcolor: 'background.paper',
						}}
						component='nav'
						aria-labelledby='nested-list-subheader'
					>
						<ListItem sx={{ padding: 0 }}>
							<ListItemButton onClick={handleTogglePastInput}>
								<ListItemIcon>
									<HistoryIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary='Last reflections' />
								{lastReflection ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
						</ListItem>

						<Collapse in={lastReflection} timeout='auto' unmountOnExit>
							<List component='div' disablePadding>
								<ListItemButton
									disabled={!draft}
									sx={{ pl: 2 }}
									onClick={() => {
										if (draft) handleAddOption(draft);
									}}
								>
									<ListItemText primary={draft || 'Empty'} />
								</ListItemButton>
							</List>
						</Collapse>

						<ListItem sx={{ padding: 0 }}>
							<ListItemButton onClick={handleToggleUserSentence}>
								<ListItemIcon>
									<AccountBoxIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary='User Reference' />
								{userSentence ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
						</ListItem>

						<Collapse in={userSentence} timeout='auto' unmountOnExit>
							<List component='div' disablePadding>
								{quickSentence?.map((item, index) => (
									<ListItem
										key={index}
										secondaryAction={
											<IconButton
												edge='end'
												aria-label='delete'
												onClick={() => handleDelete(item)}
											>
												<DeleteIcon />
											</IconButton>
										}
									>
										<ListItemButton>
											<ListItemText
												primary={item}
												onClick={() => handleAddOption(item)}
											/>
										</ListItemButton>
									</ListItem>
								))}
								<ListItemButton onClick={handleAddUserSentence}>
									<ListItemText primary='Add new...' />
								</ListItemButton>
							</List>
							<Collapse
								in={toggleAddSentence}
								timeout='auto'
								unmountOnExit
								sx={{ mt: 1, mb: 1 }}
							>
								<TextField
									onChange={(e) => setUserInput(e.target.value)}
									value={userInput}
									onKeyPress={(e) => handleEnter(e)}
									id='outlined-textarea'
									placeholder='Enter your comment'
									sx={{ width: '100%' }}
								/>
							</Collapse>
						</Collapse>

						<ListItem sx={{ padding: 0 }}>
							<ListItemButton onClick={handleToggleSuggestedInput}>
								<ListItemIcon>
									<AddCircleIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary='Suggested Sentence' />
								{openSuggested ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
						</ListItem>
						<Collapse in={openSuggested} timeout='auto' unmountOnExit>
							<List component='div' disablePadding>
								{SAMPLE_INPUT.map((item, index) => (
									<ListItemButton key={index}>
										<ListItemText
											primary={item}
											onClick={() => handleAddOption(item)}
										/>
									</ListItemButton>
								))}
							</List>
						</Collapse>
					</List>
					<IconButton
						onClick={onToggle}
						color='error'
						sx={{
							position: 'absolute',
							top: 0,
							right: 0,
							mt: 2,
							mr: 3,
						}}
					>
						<CloseIcon />
					</IconButton>
					<SearchBar setContent={setContent} />
				</>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<>
					{sentences ? (
						<>
							{sentences.length > 2 ? (
								<div className='sentences'>
									<Typography
										sx={{
											margin: '1rem 0',
											fontSize: '1rem',
											opacity: '1',
											cursor: 'pointer',
											'&:hover': {
												color: 'blue',
											},
										}}
										onClick={() => handleCopyNote(sentences)}
									>
										{sentences}
									</Typography>

									<IconButton
										onClick={() => handleCopyNote(sentences)}
										sx={{ color: '#FF7129' }}
									>
										<ContentCopyIcon />
									</IconButton>
								</div>
							) : (
								<>
									<Stack justifyContent='center' alignItems='center' gap='10px'>
										<WarningAmberIcon sx={{ fontSize: '30px' }} />
										<Typography sx={{ fontSize: '1.2rem' }}>
											No Quick Sentences
										</Typography>
									</Stack>
								</>
							)}
						</>
					) : (
						// <>Loading...</>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: '4rem',
							}}
						>
							<CircularProgress />
						</Box>
					)}
				</>
			</TabPanel>
		</Box>
	);
};

SampleInput.propTypes = {
	onToggle: PropTypes.func,
	setContent: PropTypes.func,
};

export default SampleInput;

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<>{children}</>
				</Box>
			)}
		</div>
	);
}
